@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contain{
  padding-left: 8rem;
  padding-right: 8rem;
}

.pryBtn{
  background: #D14F0D;
  color: #FFF;
  font-weight: 500;
  border-radius: 6px;
  padding: 8px;
  transition: all ease-in-out .3s;
}

.pryBtn:hover, .secBtn:hover{
  padding-left: 10px;
  padding-right: 10px;
}
.secBtn{
  background: #12444A;
  color: #FFF;
  font-weight: 500;
  border-radius: 6px;
  padding: 8px;
  transition: all ease-in-out .3s;
}

.shadow-left {
  /* box-shadow: -6px 0px 8px 2px rgba(0, 0, 0, 0.3); */
  box-shadow: -23px 4px 32px 0px #00000040;

}

.slick-prev, .slick-next{
  z-index: 99;
  color: black !important;
}
/* box-shadow: 1px 4px 7px -1px #0000003D; */


@media screen and (max-width: 1100px){
  .contain{
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (max-width: 475px){
  .contain{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pryBtn{
    padding: 4px 10px;
    margin-top: 1rem;
  }
}